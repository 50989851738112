import { Routes, UrlMatcher, UrlSegment } from '@angular/router';
import { startseiteGuard } from './main/startseite/guard/startseite.guard';
import { StartseiteComponent } from './main/startseite/startseite.component';
import { studienfeldinfoGuard } from './main/studienfeldinfo/guard/studienfeldinfo.guard';
import { sucheGuard } from './main/suche/guard/suche-guard.service';
import { SucheComponent } from './main/suche/suche.component';
import { joinMatcher, optional } from './url-matcher-utils';

const suche: UrlMatcher = (url: UrlSegment[]) => {
  return url.length >= 1 && url[0].path === 'suche' ? { consumed: url.slice(0, 1) } : null;
};

const detailseite: UrlMatcher = (url: UrlSegment[]) =>
  url.length === 2 && url[0].path === 'studienangebot'
    ? { consumed: url, posParams: { id: url[1] } }
    : null;

const merkliste: UrlMatcher = (url: UrlSegment[]) =>
  url.length >= 1 && url[0].path === 'merkliste' ? { consumed: url.slice(0, 1) } : null;

export const APP_ROUTES: Routes = [
  {
    path: '',
    pathMatch: 'full',
    title: 'Startseite',
    component: StartseiteComponent,
    canActivate: [startseiteGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'suche',
    // Lazy Loading führt hier zu Problemen
    // Grund ist vermutlich, dass hier sehr viel Logik außderhalb der AngularZone ausgeführt wird
    // was dazu führt dass die Changedetection nicht richtig funktionieren kann.
    // Fehlerbild: z.B. Wird die obere Paginierung nicht angezeigt, wenn von einer Detailseite zurück zur Ergebnisliste navigiert wurde
    // Aktuell wird das an etlichen stellen mit `changedetector.detectChanges` versucht zu lösen.
    // Langzeit Lösung wäre es den Code an einigen Stellen zu refactoed, und vorallem manuelle Subscriptions los zu werden und stattdessen
    // mit der AsyncPipe und/oder Signals zu arbeiten.
    component: SucheComponent,
    title: 'Suchergebnisse',
    canActivate: [sucheGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'news',
    loadComponent: () =>
      import('./main/news/news/page/news-page.component').then((m) => m.NewsPageComponent),
    title: 'News'
  },
  {
    path: 'studienfelder',
    loadComponent: () =>
      import('./main/studienfelder/studienfelder.component').then((m) => m.StudienfelderComponent),
    title: 'Studienfeldauswahl'
  },
  {
    path: 'studienfeldinfo',
    canActivate: [studienfeldinfoGuard],
    loadComponent: () =>
      import('./main/studienfeldinfo/studienfeldinfo.component').then(
        (m) => m.StudienfeldinfoComponent
      )
  },
  {
    matcher: joinMatcher(optional(suche), merkliste),
    loadComponent: () =>
      import('./main/merklist/merklist.component').then((m) => m.MerklistComponent),
    title: 'Merkliste'
  },
  {
    matcher: joinMatcher(optional(suche), optional(merkliste), detailseite),
    loadComponent: () =>
      import('./main/studienangebotinfo/studienangebotinfo.component').then(
        (m) => m.StudienangebotInfoComponent
      )
  },
  {
    path: '404',
    loadComponent: () =>
      import('./angebot-nicht-gefunden/angebot-nicht-gefunden.component').then(
        (m) => m.AngebotNichtGefundenComponent
      )
  },
  {
    path: '**',
    loadComponent: () =>
      import('./seitenichtgefunden/seitenichtgefunden.component').then(
        (m) => m.SeiteNichtGefundenComponent
      )
  }
];
