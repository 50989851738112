import { DecimalPipe, NgIf, NgPlural, NgPluralCase } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FacettenResult } from '../../../../model/FacettenResult';
import { UrlParamService } from '../../services/url-param.service';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'ba-studisu-reset-all-facettes',
    templateUrl: './reset-all-facettes.component.html',
    styleUrls: ['./reset-all-facettes.component.scss'],
    imports: [NgIf, NgPlural, NgPluralCase, DecimalPipe]
})
export class ResetAllFacettesComponent {
  @Input()
  private facettenWerte: FacettenResult;

  constructor(private _urlService: UrlParamService) {}

  /**
   * Liefert die Anzahl der Suchergebnisse, die durch das Löschen aller Filter hinzukämen.
   *
   * @returns {number}
   */
  public getAdditionalResultNum(): number {
    if (null !== this.facettenWerte && this.facettenWerte.filteredOutErgebnisse > 0) {
      return this.facettenWerte.filteredOutErgebnisse;
    } else {
      return 0;
    }
  }

  /**
   * Löscht alle gesetzten Filterfacetten und lädt die Seite neu.
   */
  resetFilters() {
    this._urlService.resetFilter();
    this._urlService.updateView({ [UrlParamService.PARAM_PAGE]: 1 });
  }
}
