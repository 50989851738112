import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { MainLayoutModule, ModalModule, ModalPopupComponent } from '@infosysbub/ng-lib-dpl3';
import { Subscription } from 'rxjs';
import { StudisuError } from '../error/studisu-error';
import { StudisuErrorService } from '../error/studisuerror.service';
import { MessageService } from '../services/message.service';
import { HinweisModalData } from '../ui-components/modalhinweisdialog/hinweismodalData';
import { ModalHinweisDialogComponent } from '../ui-components/modalhinweisdialog/modalhinweisdialog.component';
import { Messages } from '../ui-components/model/Messages';
import { VersionInfoComponent } from '../versioninfo/versioninfo.component';

// no one likes declaration errors =)
// hides the type error for jQuery
// TODO REAL solution for jQuery integration in our NG App
declare var $: any;

@Component({
    changeDetection: ChangeDetectionStrategy.Default,
    selector: 'ba-studisu-main',
    templateUrl: './main.component.html',
    imports: [
        MainLayoutModule,
        RouterOutlet,
        VersionInfoComponent,
        ModalModule,
        ModalHinweisDialogComponent
    ]
})
export class MainComponent implements OnInit, OnDestroy {
  public isScrollButtonVisible;

  @ViewChild('infodialog')
  private infoDialog: ModalHinweisDialogComponent;

  @ViewChild('errorModal')
  private errorModal: ModalPopupComponent;

  private errorService: Subscription;
  private messageServiceSubscription: Subscription;

  public modalTitle;
  public modalText;

  // Message-Element im Template bereitstellen
  public messages = Messages;

  constructor(
    private errorDialogService: StudisuErrorService,
    private messageService: MessageService
  ) {}

  /**
   * initializes MainComponent-component, receives default-values from ProfilService-service
   */
  public ngOnInit() {
    this.errorService = this.errorDialogService.errors$.subscribe((error) => {
      this.buildMessage(error);
    });
    this.messageServiceSubscription = this.messageService.showHinweisDialog.subscribe(
      (hinweisData) => {
        if (hinweisData != null) {
          this.infomodalOeffnen(hinweisData);
        }
      }
    );
  }

  public ngOnDestroy(): void {
    this.errorService.unsubscribe();
    this.messageServiceSubscription.unsubscribe();
  }

  /**
   * Wird benötigt um zu einem Anker zu springen.
   * @param location
   */
  public goTo(location: string): void {
    window.location.hash = '';
    window.location.hash = location;
  }

  /**
   * Über den Decorator HostListener wird ein Listener auf
   * das Scroll-Event erzeugt. Falls die vorgegebene Scrollhöhe
   * erreicht wird, wird der Scroll-Up-Button eingeblendet.
   */
  @HostListener('window:scroll', ['$event'])
  private onScroll(): void {
    if (window.pageYOffset > 205) {
      // Bei 200 gab's noch Probleme: STUDISU-417
      this.isScrollButtonVisible = true;
    } else {
      this.isScrollButtonVisible = false;
    }
  }

  /**
   * Baut die Fehlermeldung zusammen
   * @param errorMessage: StudisuError[]
   */
  private buildMessage(errorMessage: StudisuError[]) {
    if (errorMessage.length > 0) {
      this.modalTitle = Messages.FEHLER_AUFGETRETEN;
      this.modalText = Messages.FEHLER_LADEN_DATEN;
      this.errorDialogService.clearErrors();
      this.openErrorModal();
    }
  }

  /**
   * Öffnet das Infomodal bei Klick auf das i-Icon.
   */
  private infomodalOeffnen(hinweisModalData: HinweisModalData) {
    this.infoDialog.showModalDialog(hinweisModalData);
  }

  private openErrorModal() {
    this.errorModal.openModal();
  }

  public reloadPage(): void {
    window.location.reload();
  }
}
