import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component
} from '@angular/core';
import { Messages } from '../../../ui-components/model/Messages';
import { Suchtyp } from '../services/model/Suchtyp';
import { OrtSucheComponent } from './ort-suche/ort-suche.component';
import { OrtSuchumkreisComponent } from './ort-suchumkreis/ort-suchumkreis.component';
import { TextsucheTypwahlComponent } from './textsuche-typwahl/textsuche-typwahl.component';
import { TextsucheComponent } from './textsuche/textsuche.component';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'ba-studisu-suche-form',
    templateUrl: './suche-form.component.html',
    imports: [
        TextsucheComponent,
        TextsucheTypwahlComponent,
        OrtSucheComponent,
        OrtSuchumkreisComponent
    ]
})
export class SucheFormComponent implements AfterViewChecked {
  public label = Messages;

  public suchtyp: Suchtyp = null;

  constructor(private changeDetector: ChangeDetectorRef) {}

  public ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  /**
   * Leitet die Änderung des Suchtyps vom Dropdown-Menü an die Suchkomponente weiter.
   */
  public onSuchtypChange(event: Suchtyp) {
    this.suchtyp = event;
    this.changeDetector.detectChanges();
  }
}
