import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SHOW_MODAL_ON_ERROR } from 'src/app/error/interceptor.token';
import { StudisuError } from 'src/app/error/studisu-error';
import { StudisuConfig, getStudisuConfig } from '../../config/studisu.config';
import { VersionInfo } from './model/VersionInfo';

/**
 * Service-Klasse zur Abfrage von Datenstand und Version aus dem Backend.
 *
 * @author OettlJ
 */
@Injectable()
export class VersionInfoService {
  private conf: StudisuConfig = getStudisuConfig();

  constructor(private http: HttpClient) {}

  /**
   * Methode zur Abfrage von Datenstand und Version aus dem Backend.
   * @returns {Observable<VersionInfo>}
   */
  public getVersion(): Observable<VersionInfo> {
    let url = this.conf.studienangeboteServiceHost + this.conf.versionInfoPath;
    return this.http
      .get<VersionInfo>(url, { context: new HttpContext().set(SHOW_MODAL_ON_ERROR, true) })
      .pipe(
        catchError((error) => {
          throw new StudisuError('Fehler beim Laden der Version.', error);
        })
      );
  }
}
