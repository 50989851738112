@use 'node_modules/@design/bapf-pattern-library/source/sass/settings/colors' as *;

ba-bub-typeahead #typeahead-menu-TextSuche {
  .count-label {
    font-size: 12px;
  }

  .color-grey-darker {
    color: $color-grey-darker;
  }

  .overflow-wrap-anywhere {
    overflow-wrap: anywhere;
  }

  .studisu-autocomplete-anbietername {
    margin-right: 140px;
  }

  .studisu-autocomplete-anbietername {
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 80%;
  }
}
