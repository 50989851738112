import {
  AsyncPipe,
  DecimalPipe,
  I18nPluralPipe,
  NgClass,
  NgIf,
  NgTemplateOutlet
} from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import {
  AktiverFilter,
  AnsichtSwitchModule,
  BookmarkIconModule,
  FilterChipListeModule,
  TooltipModule
} from '@infosysbub/ng-lib-dpl3';
import { flatten } from 'lodash';
import { map } from 'rxjs/operators';
import { AnsichtstypService } from '../../../../../services/ansichtstyp.service';
import { MerklistService } from '../../../../../services/merklist/merklist.service';
import { Facette } from '../../../services/model/Facetten';
import { UrlParamService } from '../../../services/url-param.service';
import { PaginierungComponent } from '../paginierung/paginierung.component';

export interface AktiverUrlFilter extends AktiverFilter {
  urlParam: string;
  key: string;
  text: string;
  name: string;
}

@Component({
    selector: 'ba-studisu-ergebnisliste-header',
    styleUrls: ['./header.component.scss'],
    templateUrl: './header.component.html',
    imports: [
        NgClass,
        NgTemplateOutlet,
        TooltipModule,
        RouterLink,
        BookmarkIconModule,
        NgIf,
        FilterChipListeModule,
        PaginierungComponent,
        AnsichtSwitchModule,
        AsyncPipe,
        DecimalPipe,
        I18nPluralPipe
    ]
})
export class HeaderComponent {
  @Input() public maximaleTreffer: number;
  @Input() public ersterTreffer: number;
  @Input() public letzterTreffer: number;
  @Input() public currentPage: number;

  public aktiveFilter: AktiverUrlFilter[];

  constructor(
    private urlParamService: UrlParamService,
    public ansichtstypService: AnsichtstypService,
    public merklistService: MerklistService
  ) {}

  private filterReihenfolge = [
    UrlParamService.PARAM_STUDT,
    UrlParamService.PARAM_REGION,
    UrlParamService.PARAM_STUDIENFORMEN,
    UrlParamService.PARAM_STUDIENMODELL,
    UrlParamService.PARAM_ABSCHLUSSGRAD,
    UrlParamService.PARAM_HOCHSCHULART,
    UrlParamService.PARAM_STUDIENANBIETER
  ];

  @Input()
  public set facetten(alleFacetten: Facette[]) {
    this.aktiveFilter = flatten(
      alleFacetten.map(({ auswahl, id: urlParam }) =>
        auswahl
          .filter(({ preset }) => preset)
          .map(({ id, label: text }) => ({
            key: id.toString(),
            text,
            urlParam,
            name: this.mapUrlParamToFilter(urlParam)
          }))
      )
    ).sort((a, b) => {
      if (this.filterReihenfolge.indexOf(a.urlParam) > this.filterReihenfolge.indexOf(b.urlParam))
        return 1;
      if (this.filterReihenfolge.indexOf(b.urlParam) > this.filterReihenfolge.indexOf(a.urlParam))
        return -1;
      return 0;
    });
  }
  merklistCount$ = this.merklistService.getMerklist().pipe(map((list) => list.length ?? 0));

  public alleFilterLoeschen() {
    this.urlParamService.resetFilter();
    this.urlParamService.updateView({ [UrlParamService.PARAM_PAGE]: 1 });
  }

  public filterEntfernen(filter: AktiverUrlFilter) {
    const weiterhinAktiv = this.aktiveFilter
      .filter((f) => f.urlParam === filter.urlParam && f.key !== filter.key)
      .map((filter) => filter.key);

    this.urlParamService.updateView({
      [filter.urlParam]: weiterhinAktiv.join(UrlParamService.VALUE_SEPARATOR)
    });
  }

  private mapUrlParamToFilter(param: string): string {
    switch (param) {
      case 'st':
        return 'Studientyp';
      case 're':
        return 'Region/Land';
      case 'sfo':
        return 'Studienform';
      case 'smo':
        return 'Studienmodell';
      case 'abg':
        return 'Abschlussgrad';
      case 'hsa':
        return 'Hochschulart';
      case 'san':
        return 'Studienanbieter';
      case 'ffst':
        return 'Eignungstest';
    }
  }
}
