import { animate, state, style, transition, trigger } from '@angular/animations';
import { AsyncPipe, NgClass, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ScreenSizeService, SuchergebnisEmptyStateModule, ViewMode } from '@infosysbub/ng-lib-dpl3';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ScrollService } from 'src/app/services/scroll/scroll.service';
import { FacettenResult } from '../../../../model/FacettenResult';
import { SearchResult } from '../../../../model/SearchResult';
import { AnsichtstypService } from '../../../../services/ansichtstyp.service';
import { EventService } from '../../../../services/event.service';
import { FeedbackComponent } from '../../../../ui-components/feedback/feedback.component';
import { Messages } from '../../../../ui-components/model/Messages';
import { UrlParamService } from '../../services/url-param.service';
import { HeaderComponent } from './header/header.component';
import { KompaktAnsichtComponent } from './kompakt-ansicht/kompakt-ansicht.component';
import { ListenAnsichtComponent } from './listen-ansicht/listen-ansicht.component';
import { PaginierungComponent } from './paginierung/paginierung.component';

@Component({
    animations: [
        trigger('loadingState', [
            state('loading', style({
                color: 'rgba(0,0,0,1.0)'
            })),
            transition('void => *', [style({ color: 'rgba(0,0,0,0.0)' }), animate('500ms 200ms')])
        ])
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'ba-studisu-ergebnisliste',
    templateUrl: './ergebnisliste.component.html',
    styleUrls: ['./ergebnisliste.component.scss'],
    imports: [
        NgClass,
        NgIf,
        HeaderComponent,
        SuchergebnisEmptyStateModule,
        NgSwitch,
        NgSwitchCase,
        KompaktAnsichtComponent,
        ListenAnsichtComponent,
        PaginierungComponent,
        FeedbackComponent,
        AsyncPipe
    ]
})
export class ErgebnislisteComponent implements OnInit, OnDestroy {
  public loading = true;
  protected keineSuchkriterienAngegeben = Messages.KEINE_SUCHKRITERIEN_ANGEGEBEN;
  protected mindestensStudienbereichErforderlich = Messages.MINDESTENS_STUDIENFACH_ERFORDERLICH;
  protected keinePassendenErgebnisse = Messages.KEINE_PASSENDEN_ERGEBNISSE;
  private eventSubscription: Subscription;
  private page = 1;
  private _searchResult: SearchResult;
  private _facettenWerte: FacettenResult;
  /**
   * Flag um zu vermeiden, dass die Meldung "Keine Ergebnisse"
   * angezeigt wird, solange nicht abschließend mit einer gültigen
   * Seite gesucht wurde.
   */
  private validPage = true;

  isNotDesktop$ = this.screenSizeService.rangeTo$(ViewMode.TABLET);

  constructor(
    private ref: ChangeDetectorRef,
    private urlParamService: UrlParamService,
    private eventService: EventService,
    private activeRoute: ActivatedRoute,
    public ansichtstypService: AnsichtstypService,
    private elementRef: ElementRef,
    private screenSizeService: ScreenSizeService,
    private scrollService: ScrollService
  ) {}

  public get currentPage() {
    return this.page;
  }

  public get isPageValid() {
    return this.validPage;
  }

  public get erstesErgebnis() {
    return (this.currentPage - 1) * 20 + 1;
  }

  public get letztesErgebnis() {
    let tempLetztesErgebnis = this.currentPage * 20;
    return this._facettenWerte.maxErgebnisse < tempLetztesErgebnis
      ? this._facettenWerte.maxErgebnisse
      : tempLetztesErgebnis;
  }

  public get searchResult(): SearchResult {
    return this._searchResult;
  }

  public get facettenWerte(): FacettenResult {
    return this._facettenWerte;
  }

  @Input()
  public set searchResult(sr: SearchResult) {
    this._searchResult = sr;
    this.ref.detectChanges();
  }

  @Input()
  public set facettenWerte(fr: FacettenResult) {
    this._facettenWerte = fr;

    if (this._facettenWerte?.facetten !== null) {
      // check if it was searched
      let pageFromActiveRouteSubscription = this.activeRoute.queryParams
        .pipe(
          map((params) => {
            let page = params[UrlParamService.PARAM_PAGE];
            return page === undefined ? '1' : page;
          })
        )
        .subscribe((page) => {
          let newPage = parseInt(page, 10);
          let anzahlSeiten = Math.ceil(this._facettenWerte?.maxErgebnisse / 20);

          // auf Seite 1 umleiten, wenn Ergebnisse vorhanden sind und
          // - Seite kleiner 1
          // - Seite größer Anzahl Seiten
          if (anzahlSeiten > 0 && (newPage < 1 || newPage > anzahlSeiten)) {
            // auf Seite 1 umleiten
            this.validPage = false;
            this.urlParamService.updateView({ [UrlParamService.PARAM_PAGE]: 1 });
          } else {
            // Seite für Paging Komponenten setzen
            this.validPage = true;
            this.page = newPage;
          }
          this.ref.detectChanges();
        });

      pageFromActiveRouteSubscription.unsubscribe();
    }
    this.ref.detectChanges();
  }

  public ngOnInit() {
    this.eventSubscription = this.eventService.studienangeboteSucheStatus.subscribe(
      (value) => {
        let isLoading = value === true;
        if (this.loading !== isLoading) {
          this.loading = isLoading;
          this.ref.detectChanges(); // Erzwinge Redraw nach Change der Liste für ChangeDetectionStrategy.OnPush
        }
      }
      // todo Fehlermeldung auswerten: falls value string, dann Fehlermeldung
    );
  }

  /**
   * Scrollt nach oben und fokussiert die Anzeige der Seitenanzahl
   */
  public fokusOnTop(): void {
    const htmlElement: HTMLElement = this.elementRef.nativeElement;
    const scrollpunkt: HTMLElement = htmlElement.querySelector('ba-studisu-ergebnisliste-header');
    this.scrollService.scrollIntoView(scrollpunkt);

    // Fokussiere Element erst, nachdem es neu-gerendert wurde.
    setTimeout(() => {
      const link: HTMLElement = htmlElement.querySelector('#page-active-top');
      link?.focus();
    }, 300);
  }

  @HostListener('document:scroll', []) ladeanimationPositionieren() {
    const element = this.elementRef.nativeElement.querySelector('#suchergebnis');
    const margin = 108;
    const value = Math.min(
      //Untere Begrenzung
      Math.max(element.offsetHeight - margin, margin),

      //Obere Begrenzung und momentane Position
      Math.max(margin, window.scrollY + window.innerHeight / 2 - element.offsetTop)
    );

    element.style.setProperty('--suchergebnis-top', value + 'px');
  }

  public ngOnDestroy() {
    this.eventSubscription.unsubscribe();
  }

  public get showMaxAlert(): boolean {
    return this._facettenWerte?.maxErgebnisse > 10000 && this.currentPage === 500;
  }
}
