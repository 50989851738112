/**
 * Angular bootstrapping
 */

/**
 * App Module
 * our top level module that holds all of our components
 */

import { registerLocaleData } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import { LOCALE_ID, enableProdMode, importProvidersFrom } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { TitleStrategy, provideRouter, withRouterConfig } from '@angular/router';
import {
  ANWENDUNGSTITEL,
  CacheBustingModule,
  GEOIS_CONFIG,
  InfosysbubNgLibModule,
  InfosysbubTitleStrategy
} from '@infosysbub/ng-lib-dpl3';
import { AppComponent } from './app/app.component';
import { APP_ROUTES } from './app/app.routes';
import { getStudisuConfig } from './app/config/studisu.config';
import { provideStudisuErrorHandler } from './app/error/error.handler';
import { provideErrorInterceptor } from './app/error/error.interceptor';
import { StudisuErrorService } from './app/error/studisuerror.service';
import { OpenFacetteService } from './app/main/suche/services/open-facette.service';
import { UrlParamService } from './app/main/suche/services/url-param.service';
import { AnsichtstypService } from './app/services/ansichtstyp.service';
import { EventService } from './app/services/event.service';
import { FlaggenService } from './app/services/flaggen.service';
import { MessageService } from './app/services/message.service';
import { HistoryService } from './app/ui-components/back/history.service';
import { VersionInfoService } from './app/versioninfo/services/versioninfo.service';

registerLocaleData(localeDe);

// feature module
if ((<any>window).ENV === 'prod') {
  enableProdMode();
}

/**
 * Bootstrap our Angular app with a top level NgModule
 */
export function main(): Promise<any> {
  return (
    bootstrapApplication(AppComponent, {
      providers: [
        provideRouter(APP_ROUTES, withRouterConfig({ paramsInheritanceStrategy: 'always' })),
        importProvidersFrom(
          BrowserModule,
          InfosysbubNgLibModule,
          ReactiveFormsModule,
          FormsModule,
          CacheBustingModule
        ),
        { provide: TitleStrategy, useExisting: InfosysbubTitleStrategy },
        { provide: ANWENDUNGSTITEL, useValue: 'Studiensuche' },
        { provide: LOCALE_ID, useValue: 'de-DE' },
        {
          provide: GEOIS_CONFIG,
          useFactory: () => {
            const config = getStudisuConfig();
            return {
              geoisStylesheetUrl: config.geoisStylesheetUrl,
              geoisScriptUrl: config.geoisScriptUrl,
              geoisImageServer: config.geoisImageServer,
              geoisGeocodeServer: config.geoisGeocodeServer
            };
          }
        },
        UrlParamService,
        EventService,
        StudisuErrorService,
        MessageService,
        OpenFacetteService,
        VersionInfoService,
        HistoryService,
        AnsichtstypService,
        FlaggenService,
        provideAnimations(),
        provideHttpClient(withInterceptorsFromDi()),
        provideErrorInterceptor(),
        provideStudisuErrorHandler()
      ]
    })
      // eslint-disable-next-line no-console
      .catch((err) => console.error(err))
  );
}

/**
 * Needed for hmr
 * in prod this is replace for document ready
 */
switch (document.readyState) {
  case 'loading':
    document.addEventListener('DOMContentLoaded', _domReadyHandler, false);
    break;
  case 'interactive':
  case 'complete':
  default:
    main();
}

function _domReadyHandler() {
  document.removeEventListener('DOMContentLoaded', _domReadyHandler, false);
  main();
}
