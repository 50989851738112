import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FacettenResult } from '../../../model/FacettenResult';
import { SearchResult } from '../../../model/SearchResult';
import { ErgebnislisteComponent } from './ergebnisliste/ergebnisliste.component';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'ba-studisu-suche-result',
    templateUrl: './suche-result.component.html',
    imports: [ErgebnislisteComponent]
})
export class SucheResultComponent {
  @Input()
  public searchResult: SearchResult;
  @Input()
  public facettenWerte: FacettenResult;
}
