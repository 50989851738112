import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { DropdownModule } from '@infosysbub/ng-lib-dpl3';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { Messages } from '../../../../ui-components/model/Messages';
import { SearchType } from '../../../../ui-components/model/SelectItem';
import { StudienfelderDelegate } from '../../../studienfelder/studienfelder.delegate';
import { Suchtyp } from '../../services/model/Suchtyp';
import { SuchworteDelegate } from '../../services/suchworte.delegate';
import { UrlParamService } from '../../services/url-param.service';

@Component({
    providers: [SuchworteDelegate, StudienfelderDelegate],
    selector: 'ba-studisu-textsuche-typwahl',
    templateUrl: './textsuche-typwahl.component.html',
    imports: [DropdownModule]
})
export class TextsucheTypwahlComponent implements OnInit, OnDestroy {
  private static FILTER_ID = UrlParamService.PARAM_SUCHTYP;

  public suchtyp = SearchType.suchwort;
  public messages = Messages;

  @Output()
  selectionChange = new EventEmitter<Suchtyp>();

  // Array mit der Konfiguration der möglichen Suchtypen
  public types: Suchtyp[] = [
    <Suchtyp>{
      type: SearchType.suchwort,
      label: Messages.SUCHWORT_EINGABEFELD_LABEL,
      placeholderText: Messages.SUCHWORT_EINGABEFELD_INFO,
      icon: 'ba-icon-search'
    },
    <Suchtyp>{
      type: SearchType.studienfach,
      label: Messages.STUDIENFACH_EINGABEFELD_LABEL,
      placeholderText: Messages.STUDIENFACH_EINGABEFELD_INFO,
      icon: 'ba-icon-learning'
    },
    <Suchtyp>{
      type: SearchType.studienfeld,
      label: Messages.STUDIENFELD_EINGABEFELD_LABEL,
      placeholderText: Messages.STUDIENFELD_EINGABEFELD_INFO,
      icon: 'ba-icon-education-provider'
    }
  ];
  public typeLabels: String[] = [
    Messages.SUCHWORT_EINGABEFELD_LABEL,
    Messages.STUDIENFACH_EINGABEFELD_LABEL,
    Messages.STUDIENFELD_EINGABEFELD_LABEL
  ];

  private onParameterChangeSubscription: Subscription;

  /**
   * constructor
   * @param urlParamService (Injected) UrlParamService for triggering url updates
   * @param hostRef
   * @param changeDetector
   */
  constructor(
    private urlParamService: UrlParamService,
    private hostRef: ElementRef,
    private changeDetector: ChangeDetectorRef
  ) {}

  /**
   * Initialization of this facette. Registers callback on url parameter change
   * which initializes default values. Subscribes on open facette event.
   */
  public ngOnInit() {
    this.selectionChange.emit(this.getCurrentSelection());
    this.changeDetector.markForCheck();
    this.onParameterChangeSubscription = this.urlParamService.currentParams
      .pipe(
        map((params) => {
          return params.get(TextsucheTypwahlComponent.FILTER_ID); // jetzt haben wir nur noch unseren Param
        }),
        distinctUntilChanged() // jetzt verarbeiten wir ihn nur, wenn er sich geändert hat
      )
      .subscribe((sty) => this.updateSuchtypWithValue(sty, false));
  }

  /**
   * OnDestroy Hook to unsubscribe made Subscriptions of this component
   */
  public ngOnDestroy() {
    this.onParameterChangeSubscription.unsubscribe();
  }

  /**
   * Liefert das Config-Objekt für den aktuell ausgewählten Suchtyp.
   */
  public getCurrentSelection(): Suchtyp {
    return this.types.find((element) => element.type === this.suchtyp);
  }

  public onChange(value) {
    this.updateSuchtypWithValue(this.convertToType(value).toString(), true);
    this.selectionChange.emit(this.getCurrentSelection());
    return false;
  }

  /**
   * Convert string-value to searchType
   * @param value als label of Suchtyp
   */
  private convertToType(value): SearchType {
    return this.types.find((t) => t.label === value).type;
  }
  /**
   * Updates current selected value, registers it on the UrlParamService.
   *
   * WARNING writes history stacks, dont call me too often
   *
   * @param value new Value for the slider
   * @param updateView if the view has to be updated
   */
  private updateSuchtypWithValue(value: string, updateView: boolean) {
    let suchtyp = parseInt(value, 10);
    if (suchtyp !== this.suchtyp) {
      if (!isNaN(suchtyp) && this.types.find((e) => e.type === suchtyp)) {
        this.suchtyp = suchtyp;
        this.selectionChange.emit(this.getCurrentSelection());
        this.changeDetector.markForCheck();
      } else {
        updateView = true;
      }
    }
    if (updateView) {
      this.urlParamService.updateView(
        {
          [TextsucheTypwahlComponent.FILTER_ID]: this.suchtyp
        },
        {
          replaceUrl: true
        }
      );
    }
  }
}
