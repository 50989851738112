import { NgIf } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { ModalModule, ModalPopupComponent } from '@infosysbub/ng-lib-dpl3';
import { Messages } from '../model/Messages';
import { HinweisModalData } from './hinweismodalData';

/**
 * Komponente zur Anzeige des Hinweis-Popups
 */
@Component({
    selector: 'ba-studisu-modal-hinweisdialog',
    templateUrl: './modalhinweisdialog.component.html',
    imports: [ModalModule, NgIf]
})
export class ModalHinweisDialogComponent {
  @ViewChild('modalPopup')
  private modal: ModalPopupComponent;

  // Message-Element im Template bereitstellen
  public messages = Messages;

  // Datenhalter für die Infoanzeige
  private _modalData: HinweisModalData;

  public showModalDialog(modalData: HinweisModalData) {
    if (modalData != null) {
      this._modalData = modalData;
      this.modal.openModal();
    }
  }

  get modalData() {
    return this._modalData;
  }
}
