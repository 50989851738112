import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { UrlParamService } from '../../suche/services/url-param.service';

/**
 * Guard der Studienfeldinfo Seite.
 *
 * Kontrolliert, ob mindestens der Studienfeld Queryparameter gesetzt ist.
 *
 * Wenn nicht, wird zur SeiteNichtGefunden Seite navigiert.
 */
export const studienfeldinfoGuard: CanActivateFn = (route) => {
  const router = inject(Router);

  return route.queryParams[UrlParamService.PARAM_STUDIENFELDER] || router.navigate(['/**']);
};
