import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TrackingService } from '@infosysbub/ng-lib-dpl3';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MainComponent } from './main/main.component';
import { HistoryService } from './ui-components/back/history.service';

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'ba-studisu-app',
    styleUrls: ['./app.component.scss'],
    templateUrl: './app.component.html',
    imports: [MainComponent]
})
export class AppComponent implements OnInit, OnDestroy {
  private previousUrl = '';
  private subscription: Subscription;

  constructor(
    private historyService: HistoryService,
    private router: Router,
    private trackingService: TrackingService
  ) {
    // Der HistoryService muss hier sein, damit er gleich zu Beginn der Anwendung
    // initalisiert wird. Nur so können von Beginn an die aufgerufenen URLs getrackt werden.
  }

  ngOnInit(): void {
    // piwik tracking für seiten-wechsel aktivieren
    this.subscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.trackingService.trackPageVisit(this.previousUrl, event.url, document.title);
        this.previousUrl = event.url;
      });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
