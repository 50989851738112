import { NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import {
  Anbieter,
  AnbieterFilterModule,
  CollapsibleFacetteModule,
  FacetteModule
} from '@infosysbub/ng-lib-dpl3';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { FacettenResult } from '../../../../model/FacettenResult';
import { OpenFacetteService } from '../../services/open-facette.service';
import { UrlParamService } from '../../services/url-param.service';

@Component({
    selector: 'ba-studisu-studienanbieter-facette',
    templateUrl: './studienanbieter-facette.component.html',
    styleUrls: ['./studienanbieter-facette.component.scss'],
    imports: [NgIf, FacetteModule, AnbieterFilterModule, CollapsibleFacetteModule, NgTemplateOutlet]
})
export class StudienanbieterFacetteComponent implements OnInit, OnChanges, OnDestroy {
  private static readonly FILTER_ID = UrlParamService.PARAM_STUDIENANBIETER;

  @Input()
  public showMobile: boolean;

  anbieter: Anbieter[] = [];
  mindestensEinAnbieterGewaehlt = false;
  mobileFacetteViewOpened = false;

  ngUnsubscribed = new Subject<void>();

  @Input()
  set facettenWerte(facettenResult: FacettenResult) {
    const anbieterFacette = facettenResult.facetten.find((facette) => facette.id === 'san');
    this.anbieter = anbieterFacette?.auswahl.map((auswahl) => ({
      id: `${auswahl.id}`,
      name: auswahl.label,
      ausgewaehlt: auswahl.preset,
      anzahlAngebote: auswahl.trefferAnzahl
    }));
    this.mindestensEinAnbieterGewaehlt =
      this.anbieter?.some((anbieter) => anbieter.ausgewaehlt) ?? false;
  }

  constructor(
    private urlParamService: UrlParamService,
    private openFacetteService: OpenFacetteService
  ) {}

  ngOnInit(): void {
    this.openFacetteService
      .getFilterIdOpened()
      .pipe(
        takeUntil(this.ngUnsubscribed),
        map((facetteId) => facetteId === StudienanbieterFacetteComponent.FILTER_ID)
      )
      .subscribe((value) => (this.mobileFacetteViewOpened = value));
  }

  ngOnChanges(): void {
    this.mobileFacetteViewOpened = false;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribed.next();
    this.ngUnsubscribed.complete();
  }

  anbieterAusgewaehlt(banIds: string[]) {
    const selectedBanIds = banIds.join(UrlParamService.VALUE_SEPARATOR);
    this.urlParamService.updateView({
      [UrlParamService.PARAM_STUDIENANBIETER]: selectedBanIds,
      [UrlParamService.PARAM_PAGE]: 1
    });
    this.mobileFacetteViewOpened = false;
  }

  anbieterAuswahlZurueckSetzen() {
    this.urlParamService.updateView({
      [UrlParamService.PARAM_STUDIENANBIETER]: null,
      [UrlParamService.PARAM_PAGE]: 1
    });
  }

  /**
   * Blendet die Filteroptionen ein bzw. aus
   */
  toggleDisplay() {
    if (this.mobileFacetteViewOpened) {
      this.mobileFacetteViewOpened = !this.mobileFacetteViewOpened;
    } else {
      this.openFacetteService.setFilterIdOpened(StudienanbieterFacetteComponent.FILTER_ID);
    }
  }
}
