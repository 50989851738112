import { NgIf } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  LiveAnnouncer,
  LoadSuggestions,
  LoggingService,
  SelectedSuggestion,
  Suggestion,
  TagcloudModule,
  TagItem,
  TypeaheadComponent,
  TypeaheadModule
} from '@infosysbub/ng-lib-dpl3';
import { of as observableOf, Subscription } from 'rxjs';
import { catchError, distinctUntilChanged, map } from 'rxjs/operators';
import { Messages } from '../../../../ui-components/model/Messages';
import { Ort } from '../../services/model/Ort';
import { OrtService } from '../../services/ort.service';
import { UrlParamService } from '../../services/url-param.service';

@Component({
    changeDetection: ChangeDetectionStrategy.Default,
    providers: [OrtService],
    selector: 'ba-studisu-ort-suche',
    templateUrl: './ort-suche.component.html',
    imports: [TypeaheadModule, NgIf, TagcloudModule]
})
export class OrtSucheComponent implements OnInit, OnDestroy {
  public messages = Messages;
  public disabled = false;
  private valueInput;

  /**
   * Text, der im Eingabefeld der Ortsauswahl erscheint;
   */
  public placeholderText: string = Messages.ORT_EINGABEFELD_INFO;

  /**
   * Text, der im Eingabefeld der Ortsauswahl erscheint;
   * ab 3 Orten erscheint der fachlich gewünschte Text
   */
  public placeholderDisabledText = Messages.ORT_AUSWAHL_MAX_ORTE;
  /**
   * Tooltip für den "Alle Löschen" Link
   */
  public deleteAllTooltip = Messages.GEWAEHLTE_ORTE_ALLE_LOESCHEN_TOOLTIP;
  //  Muster für ein Suchwort für beispielsweise Orte oder Berufe (erlaubter Zeichensatz ist gleich)
  private readonly ORT_BERUF_SUCHWORT_PATTERN =
    '[\\sa-zA-ZäöüàâæçèéêëîïôœùûÿÀÂÆÇÈÉÊËÎÏÔŒÙÛŸÄÖÜß/().,-]+';
  private readonly PLZ_PATTERN = '[0-9]\\d{1,5}';

  // Analoge REGEX sind auch im Backend zu finden und ggb. parallel anzupassen
  // de.ba.bub.studisu.inhaltsbaustein.commands.ErmittleStudienangeboteFuerMetasuche
  //  Muster für einen Laengen oder Breitengrad
  private readonly LAENGEN_BREITENGRAD_PATTERN = '[1-9]\\d{0,1}.\\d{0,8}';
  //  Muster für die Suchwort Eingabe im Ortssuchfeld (Erlaubt sind Ortsnamen oder eine PLZ)
  private readonly VALIDATE_REGEX_ORT_EINGABE =
    '^(\\s*|\\d{1,5}|' + this.ORT_BERUF_SUCHWORT_PATTERN + ')$';
  //  Muster ausschließelich für die Validierung einer PLZ
  private readonly VALIDATE_REGEX_PLZ = '^(\\d{1,5})$';
  // eslint-disable-next-line max-len
  private readonly ORTE_PARAMETER_REGEX =
    this.ORT_BERUF_SUCHWORT_PATTERN +
    '_' +
    this.LAENGEN_BREITENGRAD_PATTERN +
    '_' +
    this.LAENGEN_BREITENGRAD_PATTERN;
  private readonly PLZ_PARAMETER_REGEX =
    this.ORT_BERUF_SUCHWORT_PATTERN +
    '_' +
    this.PLZ_PATTERN +
    '_' +
    this.LAENGEN_BREITENGRAD_PATTERN +
    '_' +
    this.LAENGEN_BREITENGRAD_PATTERN;
  //  Validierung der Laengen und Breitengrade sowie der Suchwörter entsprechend
  //  der Backend Implementierung (siehe ErmittleStudienangeboteFuerMetasuche.java)
  //  Schema: <suchwort>_<breitengrad>_<laengengrad>
  private ort = '';
  private MAX_ORTE = 3;
  private AUTOSUGGEST_PLZ_LEN = 2;
  private AUTOSUGGEST_MINLEN = 2;
  private listSuggested: Ort[] = [];
  public tagCloudItems: TagItem[] = [];
  private paramsSubscription: Subscription;

  //  Array gewaehlter Orte => Anzeige ueber FilterCloud
  @ViewChild(TypeaheadComponent, { static: false })
  private typeaheadComponent: TypeaheadComponent;

  /**
   * constructor
   * @param urlService Injected Dependency
   * @param activeRoute Injected Dependency
   * @param logger Injected Dependency
   */
  constructor(
    private urlService: UrlParamService,
    private ortService: OrtService,
    private logger: LoggingService,
    private liveAnnouncer: LiveAnnouncer,
    private cd: ChangeDetectorRef
  ) {}

  headline = () =>
    this.tagCloudItems.length > 1 ? Messages.GEWAEHLTE_ORTE : Messages.GEWAEHLTER_ORT;

  //  Two-Way-Binding
  private _selektierteOrte: Ort[] = [];

  get selektierteOrte(): Ort[] {
    return this._selektierteOrte;
  }

  set selektierteOrte(orte: Ort[]) {
    this._selektierteOrte = orte;
    this.updateOrtParam(false, false);
  }

  /**
   * Initialisierung der Komponente
   * Hier wird der zugehoerige Url Parameter ausgelesen und
   * verarbeitet
   */
  public ngOnInit() {
    this.paramsSubscription = this.urlService.currentParams
      .pipe(
        map((params) => params.get(UrlParamService.PARAM_ORTE)),
        distinctUntilChanged()
      )
      .subscribe((orteParam) => {
        this.validateOrte(orteParam);
        this.parseUrlParameters(orteParam);
        this.updateUmkreisComponent();
        this.maxOrteDisableInput();
        this.updateTagCloudItems();
      });
  }

  public ngOnDestroy() {
    if (this.paramsSubscription) {
      this.paramsSubscription.unsubscribe();
    }
  }

  /**
   * Lade-Funktion fuer die Auto-Complete-Komponente.
   * @param {string} value der Ort
   * @returns {SelectItem[]} die Auto-Complete-Verschlaege
   */
  public loadSuggestions: LoadSuggestions = (valueInput: string) => {
    this.setValueInput(valueInput);
    let value = this.valueInput.trim();

    this.listSuggested = [];
    let valid = new RegExp(this.VALIDATE_REGEX_ORT_EINGABE, 'i').test(value);
    if (!valid) {
      return observableOf([Suggestion.error(Messages.ORT_EINGABEPARAMETER_FEHLERHAFT)]);
    }
    let numeric = new RegExp(this.VALIDATE_REGEX_PLZ).test(value);
    if (numeric && value.length < this.AUTOSUGGEST_PLZ_LEN) {
      return observableOf([Suggestion.error(Messages.ORT_EINGABEPARAMETER_PLZ)]);
    }
    if (!numeric && value.length < this.AUTOSUGGEST_MINLEN) {
      return observableOf([]);
    }

    return this.ortService.getOrtBySuchwort(value, this._selektierteOrte).pipe(
      map((orte) => {
        this._selektierteOrte;
        const isPlzSearch = OrtSucheComponent.isPostleitzahl(this.valueInput);
        if (orte.length === 0) {
          //return [Suggestion.info('Geben Sie eine gültige Ortsbezeichnung oder Postleitzahl ein')];
          const alreadyFound =
            this._selektierteOrte.filter((ort) => this.matchOrt(ort, this.valueInput)).length !== 0;
          return [
            Suggestion.info(
              alreadyFound ? 'Dieser Ort/PLZ ist bereits ausgewählt' : 'Nichts gefunden'
            )
          ];
        }
        this.listSuggested = orte;
        //return orte.map((sf) => Suggestion.of(sf.name, sf));
        return orte.map((ort) =>
          Suggestion.of(isPlzSearch ? `${ort.postleitzahl} ${ort.name}` : ort.name, ort)
        );
      }),
      catchError((err) => {
        this.logger.error(Messages.FEHLER_LADEN_ORT, this);
        return observableOf([Suggestion.error(Messages.FEHLER_LADEN_ORT)]);
      })
    );
  };

  private matchOrt = (ort: Ort, searchText: String) =>
    ort.name === searchText || String(ort.postleitzahl) === searchText;

  /**
   * Speichert den neuen Ort und ruft die Auswahl auf.
   */
  public onOrtSelected(result: SelectedSuggestion) {
    const isPlzSearch = OrtSucheComponent.isPostleitzahl(result.input.substring(0, 6));

    if (!isPlzSearch) {
      (result.value as Ort).postleitzahl = undefined;
    }
    this.selectOrt(result.value);
    this.updateOrtParam(false, true);
    if (!this.sindStudienbereicheAusgewaehlt() && !this.sindStudienfelderAusgewaehlt()) {
      /*
       * Das Vorlesen der Fehlermeldung mittels live-regions hatte bei dieser Fehlermeldung (Studienbereich-suche.component)
       * nicht funktioniert, da es sich um ein Binding (für den Fehlertext) innerhalb eines mit *ngIf getoggelten divs handelt
       * Mit statischem Text im HTML hätte der andere Ansatzt funktioniert und wäre grundsätzlich zu bevorzugen.
       */
      this.liveAnnouncer.announce(Messages.FEHLER_STUDIENFELD_FEHLT);
    }
  }

  /**
   * Validiert einen neuen Ort und fuegt ihn im erfolgsfall,
   * der Auswahl hinzu
   * @param newOrt neuer Ort
   */
  public selectOrt(newOrt: Ort) {
    let hasOrte = this._selektierteOrte.length > 0;
    this._selektierteOrte.push(newOrt);
    if (!hasOrte) {
      this.urlService.updateView({ [UrlParamService.PARAM_UK]: '50' });
    }
  }

  public removeItem(ort) {
    this.selektierteOrte.forEach((val, index, arr) => {
      if (val.name === ort.value && val.postleitzahl === ort.id) {
        arr.splice(index, 1);
      }
    });
    this.updateOrtParam(false, false);
    // disabled muss geändert werden, bevor Input fokussiert wird. Der Fokus kann nicht auf ein disabled-Feld gesetzt werden
    this.maxOrteDisableInput();
    this.cd.detectChanges();
    this.fokussiereInputFeld();
    this.updateTagCloudItems();
  }

  public removeAll() {
    this._selektierteOrte = [];
    this.updateOrtParam(false, false);
  }

  private sindStudienfelderAusgewaehlt(): boolean {
    const urlParam: HttpParams = this.urlService.getUrlParamsForFrontend();
    return urlParam.has(UrlParamService.PARAM_STUDIENFELDER);
  }

  private sindStudienbereicheAusgewaehlt(): boolean {
    const urlParam: HttpParams = this.urlService.getUrlParamsForFrontend();
    return urlParam.has(UrlParamService.PARAM_STUDIENFAECHER);
  }

  /**
   * Validierung des URL-Parameters
   * Wenn mehr als drei Werte für Ort mitgegeben wurden
   * wir der letzte Wert abgeschnitten
   * @param params Orte Parameter als String
   */
  private validateOrte(orteParam: string) {
    let orteArray: string[];
    if (null != orteParam) {
      orteArray = orteParam.split(UrlParamService.VALUE_SEPARATOR);
      let orteNoDupes: Set<string> = new Set<string>();
      orteArray.filter((ort) => {
        if (!orteNoDupes.has(ort)) {
          orteNoDupes.add(ort);
          return true;
        }
      });
    }
  }

  /**
   * Prueft ob eine Ortsfragment aus dem Orte Query Parameter korrekt angegeben und formultiert ist.
   * Dabei muss die Form <ortsuchwort>_[<postleitzahl>]_<laengengrad>_<breitengrad> eingehalten werden.
   * Einzelne Ortfragmente des URL Parameters sind durch Kommata getrennt.
   * @param orteParameter Zu prüfendes Ortsfragment
   * @returns true wenn der Ort der vorgebenen Form entspricht
   */
  private ortParameterEntsprichtDerKorrektenForm(orteParameter: string): boolean {
    return (
      new RegExp(this.ORTE_PARAMETER_REGEX, 'i').test(orteParameter) ||
      new RegExp(this.PLZ_PARAMETER_REGEX, 'i').test(orteParameter)
    );
  }

  /**
   * Helper Function zum parsen der Orte aus der Url
   * @param params QueryParams des Routers
   */
  private parseUrlParameters(orte: string) {
    let orteList: string[] = orte ? orte.split(UrlParamService.VALUE_SEPARATOR) : [];
    let result: Ort[] = [];
    orteList.forEach((val) => {
      if (this.ortParameterEntsprichtDerKorrektenForm(val)) {
        result.push(this.getUrlParamAsOrt(val));
      }
    });
    this._selektierteOrte = result;

    if (this._selektierteOrte.length > this.MAX_ORTE) {
      this._selektierteOrte = this._selektierteOrte.slice(0, this.MAX_ORTE);
      this.updateOrtParam(true, false);
    }
  }

  /**
   * Konvertiert einen Ort zu einem Url Parameter String in der Form:
   * <NAME>_<LAENGENGRAD>_<BREITENGRAD>
   * @param ort ort
   */
  private getOrtAsUrlParam(ort: Ort): string {
    if (ort.postleitzahl != null) {
      return ort.name + '_' + ort.postleitzahl + '_' + ort.laengengrad + '_' + ort.breitengrad;
    } else {
      return ort.name + '_' + ort.laengengrad + '_' + ort.breitengrad;
    }
  }

  /**
   * Konvertiert einen Ort zu einem Url Parameter String in der Form:
   * <NAME>_<LAENGENGRAD>_<BREITENGRAD>
   * @param ort ort
   */
  private getUrlParamAsOrt(ort: string): Ort {
    if (!ort) {
      return null;
    }
    let values = ort.split('_');
    if (values.length === 3) {
      return new Ort(values[0], values[1], values[2]);
    } else if (values.length === 4) {
      return new Ort(values[0], values[2], values[3], values[1]);
    } else {
      return null;
    }
  }

  /**
   * Aktualisiert die aktuelle Ortsselektion mit dem UrlParamService
   * und updated das Binding
   * @param replaceUrl Aktuelle URL in der Browser-History ersetzen?
   * @param resetBundeslaender Filterung nach Bundeslaendern aufheben?
   */
  private updateOrtParam(replaceUrl: boolean, resetBundeslaender: boolean) {
    let urlParams = {
      [UrlParamService.PARAM_ORTE]: this._selektierteOrte
        .map((ort) => this.getOrtAsUrlParam(ort))
        .join(UrlParamService.VALUE_SEPARATOR),
      [UrlParamService.PARAM_PAGE]: 1
    };
    this.logger.debug(urlParams[UrlParamService.PARAM_ORTE]);

    if (this._selektierteOrte.length === 0) {
      urlParams[UrlParamService.PARAM_UK] = null;
    }

    if (resetBundeslaender) {
      urlParams[UrlParamService.PARAM_REGION] = null;
    }

    if (replaceUrl) {
      this.urlService.updateView(urlParams, { replaceUrl: true });
    } else {
      this.urlService.updateView(urlParams);
    }
  }

  /**
   * Aktualisiert die Umkreis-Komponente aufgrund des URL-Parameters ort. Falls kein Ort gewählt wurde, wird der Umkreis
   * auf Bundesweit gesetzt, falls dieser noch nicht auf Bundesweit gesetzt ist.
   */
  private updateUmkreisComponent() {
    // URL Parameter muss nur gesetzt werden, wenn kein Ort gewählt und URL Parameter noch nicht auf 'Bundesweit'
    if (
      this._selektierteOrte.length === 0 &&
      !this.urlService.hasParamWithValue(
        UrlParamService.PARAM_UK,
        Messages.URLPARAM_UK_VALUE_BUNDESWEIT
      )
    ) {
      let ukParam = Messages.URLPARAM_UK_VALUE_BUNDESWEIT;
      this.urlService.updateView({ [UrlParamService.PARAM_UK]: ukParam }, { replaceUrl: true });
    }
  }

  /**
   * STUDISU-39: Ab drei Orten im Eingabefeld wird das Feld deaktiviert;
   *             diese Methode wird in der ngOnInit Orts-Parameteränderung subscribed und aufgerufen.
   *             Auswertung des disabled-Attributs erfolgt in der Component-HTML
   */
  private maxOrteDisableInput(): void {
    if (this._selektierteOrte.length >= this.MAX_ORTE) {
      this.disabled = true;
      this.placeholderText = Messages.ORT_AUSWAHL_MAX_ORTE;
    } else {
      this.disabled = false;
      this.placeholderText = Messages.ORT_EINGABEFELD_INFO;
    }
  }

  /**
   * STUDISU-402 BF - Fokus auf Input nach Löschen eines Suchkriteriums
   * Nach dem Löschen es Ortes wird der Fokus zurück in das
   * Inputfeld gesetzt.
   */
  private fokussiereInputFeld() {
    if (this.typeaheadComponent) {
      this.typeaheadComponent.focus();
    }
  }

  private updateTagCloudItems(): void {
    this.tagCloudItems = this._selektierteOrte.map((entity) => ({
      id: entity.value,
      value: entity.name,
      angezeigterText: entity.postleitzahl ? `${entity.postleitzahl} ${entity.name}` : entity.name,
      icon: `ba-icon-${entity.icon}`,
      teaser: undefined
    }));
  }

  private static isPostleitzahl(str) {
    return /\d{1,5}$/.test(str.trim());
  }

  private setValueInput(valueInput: string) {
    this.valueInput = valueInput;
  }
}
