import { Component } from '@angular/core';
import { createImageSources } from 'src/app/utils/createImageSources';
import {
  ResponsiveKontextInfoHeaderComponent,
  Source
} from '../../ui-components/responsive-kontext-info-header/responsive-kontext-info-header.component';
import { SucheFormComponent } from '../suche/form/suche-form.component';
import { StartseiteContentComponent } from './startseite-content/startseite-content.component';

@Component({
    selector: 'ba-studisu-startseite',
    templateUrl: './startseite.component.html',
    styleUrls: ['./startseite.component.scss'],
    imports: [ResponsiveKontextInfoHeaderComponent, SucheFormComponent, StartseiteContentComponent]
})
export class StartseiteComponent {
  sources: Source[] = createImageSources('assets/images/header/Start');
}
